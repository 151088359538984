import React, { useState } from "react";
import ButtonGroup from "./sidebar/buttonGroup";
import AccountDetails from "./stepper/accountDetails";
import FxRequirement from "./stepper/fxRequirement"; // Import other components as needed
import AuthContact from "./stepper/authContact";
import KnowledgeSign from "./stepper/knowledgeSign";
import Section1 from "./section1";
import Navbar from "../layouts/navbar/navbar";

export default function Clients() {
  const [activeTab, setActiveTab] = useState("AccountDetails");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  const renderTabContent = () => {
    switch (activeTab) {
      case "AccountDetails":
        return <AccountDetails handleTabChange={handleTabChange} />;
      case "FxRequirement":
        return <FxRequirement handleTabChange={handleTabChange} />;
      case "authContact":
        return <AuthContact handleTabChange={handleTabChange} />;
      case "knowledgeSign":
        return <KnowledgeSign handleTabChange={handleTabChange} />;
      default:
        return null;
    }
  };

  return (
    <div>
      <div className="hero">
        <Navbar />
        <div className="dashboard">
          <Section1 />
          <div className="hm-container">
            <div className="row my-4">
              <div className="col-lg-4 sidebar-leftsection">
                <ButtonGroup activeTab={activeTab} handleTabChange={handleTabChange} />
              </div>
              <div className="col-lg-8 sidebar-rightsection">
                {renderTabContent()}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
