import React from 'react';
import Tiles from './components/tiles';
import Calculator from './components/calculator';
import Table from './components/table';

const Dashboard = () => {

  return (
    <div className="hero bg-white">
      <div className="landing-hero p-3 rounded-0">
        <div className="hm-container my-4">
          <h3 className='text-uppercase'>Dashboard</h3>
          {/* tiles */}
          <Tiles />
        </div>
      </div>
      <div className="hm-container my-5">
        <Calculator />
        <Table/>
      </div>
    </div>
  )
}
export default Dashboard;
