import React from 'react'
import { Link } from 'react-router-dom';

const Tiles = () => {

    const cardsData = [
        { id: 1, path: '/spot-rate', iconClass: "ti-currency-dollar", label: "Request a Spot Rate", color: "primary" },
        { id: 2, path: '/forward-rate', iconClass: "ti-link", label: "Request a Forward Rate", color: "info" },
        { id: 3, path: '/payment', iconClass: "ti-brand-paypal", label: "Multiple Payments", color: "danger" },
        { id: 4, path: '/manage-beneficiaries', iconClass: "ti-users", label: "Manage Beneficiaries", color: "success" }
    ];


    return (
        <>
            <div className="row">
                {cardsData.map((card) => (
                    <div key={card.id} className="col-sm-6 col-lg-3 mb-4">
                        <Link to={card.path}>
                            <div className={`card card-border-shadow-${card.color}`}>
                                <div className="card-body">
                                    <div className="d-flex hm-tiles-col align-items-center mb-2 pb-1 gap-3">
                                        <div className={`badge rounded-pill bg-label-${card.color} p-2`}>
                                            <i className={`ti ${card.iconClass} ti-sm hm-dashboard-icons`}></i>
                                        </div>
                                        <h5 className="text-black ms-1 mb-0 hm-tiles-label"><b>{card.label}</b> </h5>
                                    </div>
                                </div>
                            </div>
                        </Link>
                    </div>
                ))}
            </div>
        </>
    )
}

export default Tiles