import React from 'react'
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom'

const KnowledgeSign = ({ handleTabChange }) => {

  const navigate = useNavigate();

  const toDashboard = () => {
    toast.success("Welcome to Dashboard");
    navigate('/dashboard');
  }

  return (
    <>
      <div className="card">
        <div className="card-body p-4">
          <h3 className='mb-5'>
           Acknowledgement & signature 
          </h3>
          <div className="form-check mb-4">
            <input className="form-check-input" type="checkbox" name="terms&conditions" id="terms&conditions" />
            <label className="form-check-label" forHtml="terms&conditions">I have read, understood and accept the <span className='text-primary'>Terms & Conditions</span> of Equals Connect Limited</label>
          </div>
          <div className="form-check mb-4">
            <input className="form-check-input" type="checkbox" name="terms&conditions" id="terms&conditions" />
            <label className="form-check-label" forHtml="terms&conditions">I have read, understood and accept the <span className='text-primary'>Privacy Policy</span> of Equals Connect Limited</label>
          </div>
          <div className="form-check mb-4">
            <input className="form-check-input" type="checkbox" name="terms&conditions" id="terms&conditions" />
            <label className="form-check-label" forHtml="terms&conditions">I am happy to receive occasional emails about product updates, market reports and rate alerts</label>
          </div>

        </div>
        <div className="card-footer bg-light d-flex justify-content-end gap-3 p-4">
          <button
            type='submit'
            onClick={() => handleTabChange("authContact")}
            className='btn bg-white gap-2'
          >
            Back
          </button>
          <button
          onClick={toDashboard}
            type='submit'
            className='btn btn-primary gap-2'
          >
            Complete Registration <i className='fa fa-arrow-right'></i>
          </button>
        </div>
      </div>
    </>
  )
}

export default KnowledgeSign