// ButtonGroup.js
import React from 'react';

const ButtonGroup = ({ activeTab, handleTabChange }) => {
  return (
    <div className="card h-100 hm-buttons">
      <div className="card-header">
        <ul className="nav nav-pills card-header-pills hm-buttons-ul d-flex flex-column" role="tablist">

          <li className="nav-item py-3" role="presentation">
            <button type="button" onClick={() => handleTabChange("AccountDetails")} className={`nav-link display-1 justify-content-between ${activeTab === "AccountDetails" ? "active" : ""}`}>
             1. Account Details 
             <i className='fa fa-arrow-right' style={{ display: `${activeTab === "AccountDetails" ? 'block' : 'none'}` }}></i>
            </button>
          </li>

          <li className="nav-item py-3" role="presentation">
            <button type="button" onClick={() => handleTabChange("FxRequirement")} className={`nav-link display-1 justify-content-between ${activeTab === "FxRequirement" ? "active" : ""}`} >
             2. Fx Requirement
             <i className='fa fa-arrow-right' style={{ display: `${activeTab === "FxRequirement" ? 'block' : 'none'}` }}></i>
            </button>
          </li>

          <li className="nav-item py-3" role="presentation">
            <button type="button" onClick={() => handleTabChange("authContact")} className={`nav-link display-1 justify-content-between ${activeTab === "authContact" ? "active" : ""}`} >
             3. Authorized Contact
             <i className='fa fa-arrow-right' style={{ display: `${activeTab === "authContact" ? 'block' : 'none'}` }}></i>
            </button>
          </li>

          <li className="nav-item py-3" role="presentation">
            <button type="button" onClick={() => handleTabChange("knowledgeSign")} className={`nav-link display-1 text-start justify-content-between ${activeTab === "knowledgeSign" ? "active" : ""}`} >
             4. Acknowledgement & Signature
             <i className='fa fa-arrow-right' style={{ display: `${activeTab === "knowledgeSign" ? 'block' : 'none'}` }}></i>
            </button>
          </li>

          {/* Add other buttons similarly */}
        </ul>
      </div>
    </div>
  );
};

export default ButtonGroup;
