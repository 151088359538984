import React, { useState } from 'react';

const SpotRate = () => {
    const [activeStep, setActiveStep] = useState('account-details-1'); // State to track active step

    const handleStepButtonClick = (stepId) => {
        setActiveStep(stepId); // Update active step when button is clicked
    };

    return (
        <>
            <div className="landing-hero p-2">
                <div className="hm-container">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <h3 className='text-primary fw-bolder my-3 text-uppercase'>Request a Spot Rate</h3>
                            <div className="bs-stepper wizard-vertical hm-card vertical mt-4">
                                <div className="bs-stepper-header">
                                    <div className={`step ${activeStep === 'account-details-1' ? 'active' : ''}`} data-target="#account-details-1">
                                        <button type="button" className="step-trigger" onClick={() => handleStepButtonClick('account-details-1')}>
                                            <span className="bs-stepper-circle">1</span>
                                            <span className="bs-stepper-label">
                                                <span className="bs-stepper-title">Spot Rate</span>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="line"></div>
                                    <div className={`step ${activeStep === 'personal-info-1' ? 'active' : ''}`} data-target="#personal-info-1">
                                        <button type="button" className="step-trigger" onClick={() => handleStepButtonClick('personal-info-1')}>
                                            <span className="bs-stepper-circle">2</span>
                                            <span className="bs-stepper-label">
                                                <span className="bs-stepper-title">Assigned Beneficiaries</span>
                                            </span>
                                        </button>
                                    </div>
                                    <div className="line"></div>
                                    <div className={`step ${activeStep === 'social-links-1' ? 'active' : ''}`} data-target="#social-links-1">
                                        <button type="button" className="step-trigger" onClick={() => handleStepButtonClick('social-links-1')}>
                                            <span className="bs-stepper-circle">3</span>
                                            <span className="bs-stepper-label">
                                                <span className="bs-stepper-title">How would you like to pay?</span>
                                            </span>
                                        </button>
                                    </div>
                                </div>
                                <div className="bs-stepper-content">
                                    <form onSubmit={() => false}>
                                        {/* Account Details */}
                                        <div id="account-details-1" className={`content dstepper-block ${activeStep === 'account-details-1' ? 'active' : ''}`}>
                                            <div className="content-header mb-3">
                                                <h4 className="mb-0 text-black">How much do you wish to exchange?</h4>
                                            </div>
                                            <div className="row g-3 my-2">
                                                <div className="col-lg-4 my-2">
                                                    <label htmlFor="sell" className='fw-bolder'>Sell</label>
                                                </div>
                                                <div className="col-lg-4 my-2">
                                                    <input type="text" className='form-control' name='sell' placeholder='10,000' />
                                                </div>
                                                <div className="col-lg-4 my-2">
                                                    <select id="currency" className="select2 form-select" >
                                                        <option value="GBP">GBP</option>
                                                        <option value="EUR">EUR</option>
                                                        <option value="PKR">PKR</option>
                                                        <option value="IN">IN</option>
                                                    </select>
                                                </div>
                                                <div className="row d-flex flex-row">
                                                    <div className="col-5 my-2">
                                                        <label htmlFor="exchangeRate" className='fw-bolder'>Exchange Rate</label>
                                                    </div>
                                                    <div className="col-5 my-2">
                                                        <span className="badge bg-label-success me-1 ">1.16464 <i className='fa fa-arrow-up'></i></span>
                                                    </div>
                                                </div>
                                                <div className="col-lg-4 my-2">
                                                    <label htmlFor="buy" className='fw-bolder'>Buy</label>
                                                </div>
                                                <div className="col-lg-4 my-2">
                                                    <input type="text" className='form-control' name='sell' placeholder='11,646.00' />
                                                </div>
                                                <div className="col-lg-4 my-2">
                                                    <select id="currency" className="select2 form-select" >
                                                        <option value="EUR">EUR</option>
                                                        <option value="GBP">GBP</option>
                                                        <option value="PKR">PKR</option>
                                                        <option value="IN">IN</option>
                                                    </select>
                                                </div>
                                                <div className="col-lg-4 my-2">
                                                    <label htmlFor="valueDate" className='fw-bolder'>Value Date</label>
                                                </div>
                                                <div className="col-lg-4 my-2">
                                                    <input type="date" className='form-control' name='valueDate' />
                                                </div>
                                                <div className="col-lg-12">
                                                    <small>By Clicking on "Buy Currency" you are agreeing to our <span className='text-primary fw-bold'>"Terms and Conditions"</span></small>
                                                </div>
                                                <div className="col-12 my-3 d-flex gap-3 justify-content-end">
                                                    <button className='btn btn-warning gap-3'>Buy Currency <i className='fa fa-arrow-right'></i></button>
                                                </div>
                                            </div>

                                        </div>
                                        {/* Personal Info */}
                                        <div id="personal-info-1" className={`content dstepper-block ${activeStep === 'personal-info-1' ? 'active' : ''}`}>
                                            {/* Your personal info form */}
                                        </div>
                                        {/* Social Links */}
                                        <div id="social-links-1" className={`content dstepper-block ${activeStep === 'social-links-1' ? 'active' : ''}`}>
                                            {/* Your social links form */}
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default SpotRate;

