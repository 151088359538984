import { Link } from "react-router-dom";
import EUR from '../../../../assets/img/flags/european-union.png';

const Wallet = () => {
    return (
        <div className="hero section-py landing-hero p-3 rounded-0">
            <div className="hm-container">
                <div className="row">
                    <div className="col-12">
                        <div className='d-flex justify-content-between align-items-center'>
                            <h3 className="my-4 ms-3">Wallet</h3>
                            <Link className='btn bg-warning p-3 text-white' style={{ height: "max-content" }} to={'/dashboard'}>Back to Dashboard</Link>
                        </div>

                        <div className="card hm-card">
                            <div className="card-body">
                                <div className="table-responsive text-nowrap">
                                    <table className="table table-bordered">
                                        <thead>
                                            <tr className='text-center'>
                                                <th>Wallet Amount No</th>
                                                <th>Wallet Name</th>
                                                <th>Currency</th>
                                                <th>Balance</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center">
                                            <tr>
                                                <td>
                                                    <Link className="text-warning text-decoration-underline">EUR3564</Link>
                                                </td>
                                                <td>
                                                    Wallet_EUR
                                                </td>
                                                <td className='d-flex gap-2 align-items-center'>
                                                    <img src={EUR} alt="" style={{width : "25px"}}/>
                                                    <span className="fw-bold">EUR</span>
                                                </td>
                                                <td>
                                                    0
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default Wallet