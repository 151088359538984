import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const AddNewBeneficiary = () => {
    const [individual, setIndividual] = useState(true);

    return (
        <div className="hero section-py landing-hero p-3 rounded-0">
            <div className='hm-container my-5'>
                <div className="row">
                    <div className="col-12">
                        <div className='d-flex justify-content-between align-items-center'>
                            <h3 className=" ms-3"> Add New Beneficiary</h3>
                            <Link className='btn bg-warning p-3 text-white' style={{ height: "max-content" }} to={'/manage-beneficiaries'}>Back</Link>
                        </div>
                    </div>
                    <div className="col-12 mb-4">
                        <div className="bs-stepper wizard-numbered mt-2">
                            <div className="bs-stepper-header d-flex flex-column flex-md-row">
                                <div className={`step ${individual ? 'active' : ''}`} data-target="#account-details">
                                    <button type="button" className="step-trigger bg-transparent border-0" onClick={() => setIndividual(true)}>
                                        <span className="bs-stepper-circle">1</span>
                                        <span className="bs-stepper-label">
                                            <span className="bs-stepper-title">Individual</span>
                                        </span>
                                    </button>
                                </div>
                                <span className="line"> / </span>
                                <div className={`step ${!individual ? 'active' : ''}`} data-target="#personal-info">
                                    <button type="button" className="step-trigger bg-transparent border-0" onClick={() => setIndividual(false)}>
                                        <span className="bs-stepper-circle">2</span>
                                        <span className="bs-stepper-label">
                                            <span className="bs-stepper-title">Business</span>
                                        </span>
                                    </button>
                                </div>
                            </div>
                            <div className="bs-stepper-content">
                                <form onSubmit={() => false}>
                                    {individual ? (
                                        <div className="content active dstepper-block">
                                            <div className="content-header mb-3">
                                                <h6 className="mb-0">Individual</h6>
                                                <small>Enter Your Individual.</small>
                                            </div>
                                            <div className="row g-3">
                                                <div className="col-lg-6 col-md-12 col-sm-12" >
                                                    <select id="Country" className="select2 form-select" >
                                                        <option value="">Select Country</option>
                                                        <option value="Thailand">Thailand</option>
                                                        <option value="Turkey">Turkey</option>
                                                        <option value="Ukraine">Ukraine</option>
                                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                                        <option value="United Kingdom">United Kingdom</option>
                                                        <option value="United States">United States</option>
                                                    </select>
                                                </div>

                                                <div className="col-lg-6 col-md-12 col-sm-12" >
                                                    <select id="Country" className="select2 form-select" >
                                                        <option value="">Select currency</option>
                                                        <option value="Thailand">Thailand</option>
                                                        <option value="Turkey">Turkey</option>
                                                        <option value="Ukraine">Ukraine</option>
                                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                                        <option value="United Kingdom">United Kingdom</option>
                                                        <option value="United States">United States</option>
                                                    </select>
                                                </div>

                                                <div className="col-lg-6 col-md-12 col-sm-12" >
                                                    <label className="form-label" for="name">Full Name</label>
                                                    <input type="text" id="name" className="form-control" />
                                                </div>

                                                <div className="col-lg-6 col-md-12 col-sm-12" >
                                                    <label className="form-label" for="name">Beneficiary Address</label>
                                                    <input type="text" id="name" className="form-control" />
                                                </div>

                                                <div className="col-lg-6 col-md-12 col-sm-12" >
                                                    <label className="form-label" for="email">Email (Optional)</label>
                                                    <input type="text" id="email" className="form-control" />
                                                </div>

                                                <div className="col-sm-12 col-md-6 col-lg-6">
                                                    <label className='form-label' htmlFor="contactNo">Contact No (Optional)</label>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <select id="numberCode" className="select2 form-select">
                                                                <option value="">+44</option>
                                                                <option value="Thailand">+92</option>
                                                                <option value="Turkey">+91</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-9">
                                                            <input className="form-control" type="text" id="contactNumber" name="contactNumber" placeholder="Contact Number" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-12 col-sm-12" >
                                                    <label className="form-label" for="accountNo">IBAN / Account No</label>
                                                    <input type="text" id="accountNo" className="form-control" />
                                                </div>

                                                <div className="col-lg-6 col-md-12 col-sm-12" >
                                                    <label className="form-label" for="reference">Default Payment Reference</label>
                                                    <input type="text" id="reference" className="form-control" />
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-check text-primary">
                                                        <input className="form-check-input" type="checkbox" name="terms&conditions" id="terms&conditions" />
                                                        <label className="form-check-label" forHtml="terms&conditions">Additional Information</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            <div className="content-header mb-3">
                                                <h6 className="mb-0">Business</h6>
                                                <small>Enter Your Business.</small>
                                            </div>
                                            <div className="row g-3">
                                                <div className="col-lg-6 col-md-12 col-sm-12" >
                                                    <select id="Country" className="select2 form-select" >
                                                        <option value="">Select Country</option>
                                                        <option value="Thailand">Thailand</option>
                                                        <option value="Turkey">Turkey</option>
                                                        <option value="Ukraine">Ukraine</option>
                                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                                        <option value="United Kingdom">United Kingdom</option>
                                                        <option value="United States">United States</option>
                                                    </select>
                                                </div>

                                                <div className="col-lg-6 col-md-12 col-sm-12" >
                                                    <select id="Country" className="select2 form-select" >
                                                        <option value="">Select currency</option>
                                                        <option value="Thailand">Thailand</option>
                                                        <option value="Turkey">Turkey</option>
                                                        <option value="Ukraine">Ukraine</option>
                                                        <option value="United Arab Emirates">United Arab Emirates</option>
                                                        <option value="United Kingdom">United Kingdom</option>
                                                        <option value="United States">United States</option>
                                                    </select>
                                                </div>

                                                <div className="col-lg-6 col-md-12 col-sm-12" >
                                                    <label className="form-label" for="companyName">Company Name</label>
                                                    <input type="text" id="companyName" className="form-control" />
                                                </div>

                                                <div className="col-lg-6 col-md-12 col-sm-12" >
                                                    <label className="form-label" for="address">Beneficiary Address</label>
                                                    <input type="text" id="address" className="form-control" />
                                                </div>

                                                <div className="col-lg-6 col-md-12 col-sm-12" >
                                                    <label className="form-label" for="email">Email (Optional)</label>
                                                    <input type="text" id="email" className="form-control" />
                                                </div>

                                                <div className="col-sm-12 col-md-6 col-lg-6">
                                                    <label className='form-label' htmlFor="contactNo">Contact No (Optional)</label>
                                                    <div className="row">
                                                        <div className="col-3">
                                                            <select id="numberCode" name="contactNumber" className="select2 form-select">
                                                                <option value="">+44</option>
                                                                <option value="Thailand">+92</option>
                                                                <option value="Turkey">+91</option>
                                                            </select>
                                                        </div>
                                                        <div className="col-9">
                                                            <input className="form-control" type="text" id="contactNumber" name="contactNumber" placeholder="Contact Number" />
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="col-lg-6 col-md-12 col-sm-12" >
                                                    <label className="form-label" for="accountNo">IBAN / Account No</label>
                                                    <input type="text" id="accountNo" className="form-control" />
                                                </div>

                                                <div className="col-lg-6 col-md-12 col-sm-12" >
                                                    <label className="form-label" for="reference">Default Payment Reference</label>
                                                    <input type="text" id="reference" className="form-control" />
                                                </div>

                                                <div className="col-12">
                                                    <div className="form-check text-primary">
                                                        <input className="form-check-input" type="checkbox" name="terms&conditions" id="terms&conditions" />
                                                        <label className="form-check-label" forHtml="terms&conditions">Additional Information</label>
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    )}

                                </form>
                            </div>
                            <div className="col-12 d-flex p-4 gap-2">
                                <button className="btn bg-warning text-white border-0">Save</button>
                                <button className="btn bg-light text-white border-0">Cancel</button>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    )
}

export default AddNewBeneficiary