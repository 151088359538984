import React from 'react'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../../../assets/img/favicon/favicon.ico'

const Navbar = () => {

    const location = useLocation();

    return (
        <>
            <nav className={`navbar bg-white py-4 ${location.pathname === '/dashboard' ? '' : 'hm-card'}`}>
                <div className="container">
                    <Link className="navbar-brand gap-3 d-flex align-items-center" to="/dashboard">
                        <img src={logo} alt="" />
                        <b>EQUALS <br /> Money</b>
                    </Link>
                    <div className='gap-2 d-flex align-items-center'>
                        <span className='text-black fw-bold'>Benjamin Granger (CFX1248)</span>
                        <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                            <span className="navbar-toggler-icon"></span>
                        </button>
                    </div>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" style={{ boxShadow: "none" }}>
                        <div className="offcanvas-header">
                            <Link className="navbar-brand gap-3 d-flex align-items-center" to="/dashboard">
                                <img src={logo} alt="" />
                                <b>EQUAL Money</b>
                            </Link>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body bg-transparent">
                            <ul className="navbar-nav flex-grow-1 pe-3 gap-3  nav-pills card-header-pills d-flex flex-column justify-content-start">
                                <li className="nav-item">
                                    <Link className={`nav-link display-1 gap-2 justify-content-start ps-3 hm-offcanvas-link ${location.pathname === '/dashboard' || location.pathname === '/spot-rate' || location.pathname === '/payment' ? 'active' : ''}`} aria-current="page" to={"/dashboard"}><i className="menu-icon tf-icons ti ti-layout-kanban"></i> Dashboard</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link display-1 gap-2 justify-content-start ps-3 hm-offcanvas-link ${location.pathname === '/profile' ? 'active' : ''}`} to={"/profile"}><i className="ti ti-user-check me-2"></i> Profile</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link display-1 gap-2 justify-content-start ps-3 hm-offcanvas-link ${location.pathname === '/deal-history' ? 'active' : ''}`} to={"/deal-history"}><i className="menu-icon tf-icons ti ti-id"></i> Deal History</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link display-1 gap-2 justify-content-start ps-3 hm-offcanvas-link ${location.pathname === '/payment-history' ? 'active' : ''}`} to={"/payment-history"}><i className="menu-icon tf-icons ti ti-file-dollar"></i> Payment History</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link display-1 gap-2 justify-content-start ps-3 hm-offcanvas-link ${location.pathname === '/manage-beneficiaries' || location.pathname === '/manage-beneficiaries/add-new-beneficiaries' ? 'active' : ''}`} to={"/manage-beneficiaries"}><i className="menu-icon tf-icons ti ti-users"></i> Manage Beneficiaries</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className={`nav-link display-1 gap-2 justify-content-start ps-3 hm-offcanvas-link ${location.pathname === '/' ? 'active' : ''}`} to={"/"}><i className="ti ti-logout me-2"></i> Logout</Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar