import React from 'react'
import GBP from '../../../../assets/img/flags/united-kingdom.png';
import EUR from '../../../../assets/img/flags/european-union.png';
import EURCoin from '../../../../assets/img/flags/euro.png';
import GBPCoin from '../../../../assets/img/flags/money.png';
import { Link } from 'react-router-dom';

const BeneficiaryManagent = () => {
    return (
        <>
            <div className="hero section-py landing-hero p-3 rounded-0">
                <div className="hm-container">
                    <div className="row">
                        <div className="col-12">
                            <div className='d-flex justify-content-between align-items-center'>
                                <h3 className="my-4 ms-3">Beneficiaries Managment</h3>
                                <Link className='btn bg-warning p-3 text-white' style={{ height: "max-content" }} to={'/manage-beneficiaries/add-new-beneficiaries'}>+ Add New Beneficiary</Link>
                            </div>
                            <div className="card hm-card my-4">
                                <div className="card-body">
                                    <form className="dt_adv_search" method="GET">
                                        <div className="row">
                                            <div className="col-12">
                                                <div className="row g-3">
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                        <input type="text" className="form-control dt-input dt-full-name" data-column="1" placeholder="Beneficiary Name" data-column-index="0" />
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                        <select id="Country" className="select2 form-select" >
                                                            <option value="">Select Country</option>
                                                            <option value="Thailand">Thailand</option>
                                                            <option value="Turkey">Turkey</option>
                                                            <option value="Ukraine">Ukraine</option>
                                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                                            <option value="United Kingdom">United Kingdom</option>
                                                            <option value="United States">United States</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-12 col-sm-12 col-md-6 col-lg-4">
                                                        <select id="Country" className="select2 form-select" >
                                                            <option value="">Select Currency</option>
                                                            <option value="Thailand">Thailand</option>
                                                            <option value="Turkey">Turkey</option>
                                                            <option value="Ukraine">Ukraine</option>
                                                            <option value="United Arab Emirates">United Arab Emirates</option>
                                                            <option value="United Kingdom">United Kingdom</option>
                                                            <option value="United States">United States</option>
                                                        </select>
                                                    </div>
                                                    <div className="col-12">
                                                        <button type='submit' className='btn btn-dark gap-2' disabled>
                                                            Addvance Search ---
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                                <div className="card-footer bg-light d-flex justify-content-end gap-2 p-3">
                                    <button type='submit' className='btn btn-primary gap-2'>
                                        <i className='fa fa-search'></i>  Search
                                    </button>
                                    <button type='submit' className='btn btn-danger gap-2'>
                                        <i className='fa fa-trash'></i>  Clear
                                    </button>
                                </div>
                            </div>

                            <div className="card hm-card">
                                <div className="card-body">
                                    <div className="table-responsive text-nowrap">
                                        <table className="table table-bordered">
                                            <thead>
                                                <tr className='text-center'>
                                                    <th>Beneficiary</th>
                                                    <th>Country</th>
                                                    <th>Bank Details</th>
                                                    <th>Currency</th>
                                                    <th>Actions</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        <span className="badge bg-label-warning rounded-pill p-2">
                                                            <i className="ti ti-user"></i>
                                                        </span>
                                                        <span className="fw-medium ps-2">Michel Byers</span>
                                                    </td>
                                                    <td className='d-flex gap-2 align-items-center'>
                                                        <img src={GBP} alt="Avatar" style={{ width: "30px" }} />
                                                        <span className="fw-medium">United Kingdom</span>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex flex-column text-center'>
                                                            <span className="fw-medium">IBAN : ES1436545213543513513</span>
                                                            <span className="fw-medium">SWIFT/BIC Code : BSA40015</span>
                                                        </div>
                                                    </td>
                                                    <td className='d-flex gap-2 align-items-center'>
                                                        <img src={GBPCoin} alt="Avatar" className="rounded-circle" style={{ width: "35px" }} />
                                                        <span className="fw-medium">GBP</span>
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                                <i className="ti ti-dots-vertical"></i>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <Link className="dropdown-item"><i className="ti ti-send me-1"></i> Send Money</Link>
                                                                <Link className="dropdown-item"><i className="ti ti-pencil me-1"></i> Edit</Link>
                                                                <Link className="dropdown-item"><i className="ti ti-trash me-1"></i> Delete</Link>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <span className="badge bg-label-primary rounded-pill p-2">
                                                            <i className="ti ti-user"></i>
                                                        </span>
                                                        <span className="fw-medium ps-2">Benjamin Granger</span>
                                                    </td>
                                                    <td className='d-flex gap-2 align-items-center'>
                                                        <img src={EUR} alt="Avatar" style={{ width: "33px" }} />
                                                        <span className="fw-medium">Italy</span>
                                                    </td>
                                                    <td>
                                                        <div className='d-flex flex-column text-center'>
                                                            <span className="fw-medium">Account No : 522135458</span>
                                                            <span className="fw-medium">Sort Code : 40015</span>
                                                        </div>
                                                    </td>
                                                    <td className='d-flex gap-2 align-items-center'>
                                                        <img src={EURCoin} alt="Avatar" className="rounded-circle" style={{ width: "30px" }} />
                                                        <span className="fw-medium">EUR</span>
                                                    </td>
                                                    <td>
                                                        <div className="dropdown">
                                                            <button type="button" className="btn p-0 dropdown-toggle hide-arrow" data-bs-toggle="dropdown">
                                                                <i className="ti ti-dots-vertical"></i>
                                                            </button>
                                                            <div className="dropdown-menu">
                                                                <Link className="dropdown-item"><i className="ti ti-send me-1"></i> Send Money</Link>
                                                                <Link className="dropdown-item"><i className="ti ti-pencil me-1"></i> Edit</Link>
                                                                <Link className="dropdown-item"><i className="ti ti-trash me-1"></i> Delete</Link>
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default BeneficiaryManagent