import React, { useState } from 'react'
import MyVerticallyCenteredModal from './modal/modal';
import { Button } from 'react-bootstrap';

const AccountDetails = ({ handleTabChange }) => {

    const [modalShow, setModalShow] = React.useState(false);


    const [formData, setFormData] = useState({
        businessName: '',
        registrationNo: '',
        postcode: '',
        // Add other form fields here
    });

    // Function to handle input change
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    const handleSelectChange = (e) => {
        const { name, value } = e.target;
        setFormData({
            ...formData,
            [name]: value
        });
    };

    // Function to check if all fields are filled
    // const isFormFilled = () => {
    //     for (const field in formData) {
    //         if (formData[field].trim() === '') {
    //             return false;
    //         }
    //     }
    //     return true;
    // };

    return (
        <div className='card d-flex h-100'>
            <div className="card-body p-4">
                <div className="row align-items-center">

                    <div className="col-sm-12 col-md-12 col-lg-5 my-2">
                        <input className="form-control" type="text" id="businessName" name="businessName" placeholder="Business Name"
                            value={formData.businessName}
                            onChange={handleInputChange} />
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3 my-2">
                        <input className="form-control" type="text" id="registrationNo" name="registrationNo" placeholder="Registration No"
                            value={formData.registrationNo}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-4 my-2 p-0 py-0 px-0">
                        <button type="submit" className="btn btn-primary">Search Companies</button>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-5 my-2">
                        <select id="Country" className="select2 form-select" onChange={handleSelectChange}>
                            <option value="">Select Country</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="United States">United States</option>
                        </select>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-4 my-2">
                        <input className="form-control" type="text" id="postcode" name="postcode" placeholder="Post Code"
                            value={formData.postcode}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3 my-2">
                        <Button className="btn btn-primary px-auto" onClick={() => setModalShow(true)}>
                            Find Address
                        </Button>
                        <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)}
                        />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 my-2">
                        <input className="form-control" type="text" id="address" name="address" placeholder="Address"
                            value={formData.address}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 my-2">
                        <input className="form-control" type="text" id="website" name="website" placeholder="Website"
                            value={formData.website}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 my-2">
                        <select id="Country" className="select2 form-select" onChange={handleSelectChange}>
                            <option value="">Select Business Type</option>
                            <option value="">Limited Company</option>
                            {/* add more */}
                        </select>
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-2 my-2">
                        <select id="numberCode" className="select2 form-select" onChange={handleSelectChange}>
                            <option value="">+44</option>
                            <option value="Thailand">+92</option>
                            <option value="Turkey">+91</option>
                        </select>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 my-2">
                        <input className="form-control" type="text" id="contactNumber" name="contactNumber" placeholder="Business Contact Number"
                            value={formData.contactNumber}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 my-2">
                        <select id="industry" className="select2 form-select" onChange={handleSelectChange}>
                            <option value="">Select Industry</option>
                            <option value="airCraft">Air Craft</option>
                            <option value="charity">Charity</option>
                            {/* add more */}
                        </select>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 my-2">
                        <select id="subIndustry" className="select2 form-select" onChange={handleSelectChange}>
                            <option value="">Select Sub Industry</option>
                            <option value="">Specialty Store</option>
                            {/* add more */}
                        </select>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 my-3">
                        <button type="dis" className="btn btn-warning px-auto" disabled>--- Your Details</button>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-5 my-2">
                        <input className="form-control" type="text" id="firstName" name="firstName" placeholder="First Name"
                            value={formData.firstName}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-4 my-2">
                        <input className="form-control" type="text" id="lastName" name="lastName" placeholder="Last Name"
                            value={formData.lastName}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3 my-2 p-0">
                        <button type="submit" className="btn btn-primary">Find Contact</button>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 my-2 d-flex">
                        <small>Date of Birth : </small>
                        <input className="form-control" id="dateOfBirth" name="dateOfBirth" type="date"
                            value={formData.dateOfBirth}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-2 my-2">
                        <select id="numberCode" className="select2 form-select" onChange={handleSelectChange}>
                            <option value="">+44</option>
                            <option value="Thailand">+92</option>
                            <option value="Turkey">+91</option>
                        </select>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-4 my-2">
                        <input className="form-control" type="text" id="contactNumber" name="contactNumber" placeholder="Mobile Number"
                            value={formData.contactNumber}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3 my-2">
                        <input className="form-control" type="text" id="userpostcode" name="userpostcode" placeholder="Post Code"
                            value={formData.userpostcode}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col-sm-12 col-md-6 col-lg-3 my-2">
                        <Button className="btn btn-primary px-auto" onClick={() => setModalShow(true)}>
                            Find Address
                        </Button>
                        <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)}
                        />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 my-3">
                        <select id="userCountry" className="select2 form-select" onChange={handleSelectChange}>
                            <option value="">Select Country</option>
                            <option value="Thailand">Thailand</option>
                            <option value="Turkey">Turkey</option>
                            <option value="Ukraine">Ukraine</option>
                            <option value="United Arab Emirates">United Arab Emirates</option>
                            <option value="United Kingdom">United Kingdom</option>
                            <option value="United States">United States</option>
                        </select>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 my-2">
                        <input className="form-control" type="text" id="userAddress" name="userAddress" placeholder="Address"
                            value={formData.userAddress}
                            onChange={handleInputChange}
                        />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 my-2">
                        <input className="form-control" type="email" id="email" name="email" placeholder="Email"
                            value={formData.email}
                            onChange={handleInputChange}
                        />
                    </div>

                </div>
            </div>
            <div className="card-footer bg-light d-flex justify-content-end p-4">
                <button
                    type='submit'
                    onClick={() => handleTabChange("FxRequirement")}
                    className='btn btn-primary gap-2'
                // disabled={!isFormFilled()} // Disable button if form is not filled
                >
                    Continue <i className='fa fa-arrow-right'></i>
                </button>
            </div>
        </div>
    )
}

export default AccountDetails