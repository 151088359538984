import React from 'react'
import Table from './components/table/table'
import Form from './components/form/form'

const PaymentHistory = () => {
    return (
        <>
            <div className="hero section-py landing-hero p-3 rounded-0">
                <div className="hm-container">
                    <div className="row">
                        <div className="col-12">
                            <div className="card hm-card my-3">
                                <div className="card-header">
                                    <div className='d-flex justify-content-between align-items-center'>
                                        <h3 className="my-1">Payment History</h3>
                                    </div>
                                </div>
                                <hr className='m-0' />
                                <div className="card-body">
                                    <Form />
                                </div>
                                <div className="card-footer bg-light d-flex justify-content-end gap-2 p-3">
                                    <button type='submit' className='btn btn-primary gap-2'>
                                        <i className='fa fa-search'></i>  Search
                                    </button>
                                    <button type='submit' className='btn btn-danger gap-2'>
                                        <i className='fa fa-trash'></i>  Clear
                                    </button>
                                </div>
                            </div>
                            <div className="card hm-card">
                                <div className="card-header flex-column flex-md-row">
                                    <div className="dt-action-buttons text-end pt-3 pt-md-0">
                                        <div className="dt-buttons">
                                            <button className="dt-button buttons-collection dropdown-toggle btn btn-label-primary me-2 waves-effect waves-light" tabindex="0" aria-controls="DataTables_Table_0" type="button" aria-haspopup="dialog" aria-expanded="false">
                                                <span>
                                                    <i className="ti ti-file-export me-sm-1"></i>
                                                    <span className="d-none d-sm-inline-block">Export</span>
                                                </span>
                                                <span className="dt-down-arrow">▼</span>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body">
                                    <Table />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default PaymentHistory