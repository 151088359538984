import React from 'react'
import ctaBackground from '../../../../assets/img/front-pages/backgrounds/cta-bg.png';


const Section1 = () => {
    return (
        <>
            <section id="landingCTA" className="section-py landing-cta p-5" style={{ backgroundImage: `url(${ctaBackground})` }}>
                <div className="container">
                    <div className="row align-items-center justify-content-center gy-lg-0">
                        <div className="col-lg-12 text-center">
                            <h6 className="h2 text-primary fw-bold mb-1">Bussiness Account Registration</h6>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Section1