import React from 'react'

const Form = () => {
    return (
        <>
            <form className="dt_adv_search" method="GET">
                <div className="row">
                    <div className="col-12">
                        <div className="row g-3">
                            <div className="col-sm-12 col-md-6 col-lg-3">
                                <input type="text" className="form-control dt-input dt-full-name" data-column="1" placeholder="Deal Number" data-column-index="0" />
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-3">
                                <input className="form-control" type="date" placeholder='Booking Date From'/>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-3">
                                <input className="form-control" type="date" placeholder='Booking Date To'/>
                            </div>
                            <div className="col-sm-12 col-md-6 col-lg-3">
                                <select id="Country" className="select2 form-select" >
                                    <option value="">Purchased Currency</option>
                                    <option value="Thailand">Thailand</option>
                                    <option value="Turkey">Turkey</option>
                                    <option value="Ukraine">Ukraine</option>
                                    <option value="United Arab Emirates">United Arab Emirates</option>
                                    <option value="United Kingdom">United Kingdom</option>
                                    <option value="United States">United States</option>
                                </select>
                            </div>
                            <div className="col-12">
                                <button type='submit' className='btn btn-dark gap-2' disabled>
                                    Addvance Search ---
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </>
    )
}

export default Form