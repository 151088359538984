import React from 'react'
import Select from 'react-select'

const FxRequirement = ({ handleTabChange }) => {


    const purposeOfTransfer = [
        { value: 'Business acquisition', label: 'Business acquisition' },
        { value: 'Business costs/revenue', label: 'Business costs/revenue' },
        { value: 'Charity donation', label: 'Charity donation' },
        { value: 'Dividend payments', label: 'Dividend payments' },
        { value: 'Loan', label: 'Loan' },
        { value: 'One off payments', label: 'One off payments' },
        { value: 'Salary payment', label: 'Salary payment' },
        { value: 'Other', label: 'Other' }
    ]

    const dealingWith = [
        { value: '(GBP) Sterling', label: '(GBP) Sterling' },
        { value: '(EUR) Euro', label: '(EUR) Euro' },
        { value: '(USD) Us Dollar', label: '(USD) Us Dollar' },
        { value: '(AED) Arab Emirate Dirham', label: '(AED) Arab Emirate Dirham' }
    ]

    const sourceFunds = [
        { value: 'Funcding from own account', label: 'Funcding from own account' },
        { value: 'Loan', label: 'Loan' },
        { value: 'Payment(s) from linked company account', label: 'Payment(s) from linked company account' },
        { value: 'Third party payment(s) from customers', label: 'Third party payment(s) from customers' }
    ]

    const Countries = [
        { value: 'United Kingdom', label: 'United Kingdom' },
        { value: 'United State', label: 'United State' },
        { value: 'United Arab Emirate', label: 'United Arab Emirate' }
    ]

    const aboutEquals = [
        { value: 'Advertisement', label: 'Advertisement' },
        { value: 'Contacted by Equals', label: 'Contacted by Equals' },
        { value: 'Existing client', label: 'Existing client' } ,
        { value: 'Internet', label: 'Internet' }
    ]

    return (
        <div className='card d-flex h-100'>
            <div className="card-body p-4">
                <div className="row align-items-center">

                    <div className="col-sm-12 col-md-12 col-lg-6 my-2">
                        <Select
                            isMulti
                            options={purposeOfTransfer}
                            name="status"
                            id="status"
                            className="basic-multi-select"
                            classNamePrefix="select"
                        // Use handleSelectChange function here
                        />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 my-2">
                        <Select
                            isMulti
                            options={dealingWith}
                            name="status"
                            id="status"
                            className="basic-multi-select"
                            classNamePrefix="select"
                        // Use handleSelectChange function here
                        />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 my-2">
                        <select id="Country" className="select2 form-select">
                            <option value="">Approximate numbers of payments (per month)</option>
                            <option value="1-5 payments per month">1-5 payments per month</option>
                            <option value="6-10 payments per month">6-10 payments per month</option>
                        </select>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 my-2">
                        <select id="Country" className="select2 form-select">
                            <option value="">Frequency of transfers</option>
                            <option value="Monthly">Monthly</option>
                            <option value="Weekly">Weekly</option>
                            <option value="Daily">Daily</option>
                        </select>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 my-2">
                        <Select
                            isMulti
                            options={sourceFunds}
                            name="status"
                            id="status"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Source of funds"
                        // Use handleSelectChange function here
                        />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-6 my-2">
                        <select id="Country" className="select2 form-select">
                            <option value="">Estimate Annual volume (€)</option>
                            <option value="€0-€100k">€0-€100k</option>
                            <option value="€100k-€250k">€100k-€250k</option>
                            <option value="€250k-€500k">€250k-€500k</option>
                            <option value="€500k-€1mil">€500k-€1mil</option>
                        </select>
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 my-2">
                        <Select
                            isMulti
                            options={Countries}
                            name="status"
                            id="status"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Country Receiving Payments From"
                        // Use handleSelectChange function here
                        />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 my-2">
                    <Select
                            isMulti
                            options={Countries}
                            name="status"
                            id="status"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Country Making Payments To" 
                        // Use handleSelectChange function here
                        />
                    </div>

                    <div className="col-sm-12 col-md-12 col-lg-12 my-2">
                    <Select
                            isMulti
                            options={aboutEquals}
                            name="status"
                            id="status"
                            className="basic-multi-select"
                            classNamePrefix="select"
                            placeholder="Where did you hear about Equals?"
                        // Use handleSelectChange function here
                        />
                    </div>

                </div>
            </div>
            <div className="card-footer bg-light d-flex justify-content-end gap-3 p-4">
                <button
                    type='submit'
                    onClick={() => handleTabChange("AccountDetails")}
                    className='btn bg-white gap-2'
                >
                    Back
                </button>
                <button
                    type='submit'
                    onClick={() => handleTabChange("authContact")}
                    className='btn btn-primary gap-2'
                >
                    Continue <i className='fa fa-arrow-right'></i>
                </button>
            </div>
        </div>
    )
}

export default FxRequirement