import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';


function MyVerticallyCenteredModal(props) {
    return (
        <Modal
            {...props}
            size="xl"
            dialogClassName="modal-90w"
            aria-labelledby="example-custom-modal-styling-title"
        >
            <Modal.Header closeButton>
                <Modal.Title id="example-custom-modal-styling-title">
                    Select Address
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {/* Table */}
                <div className="row">
                    <div className="col-md-12">
                        <div className="card mb-4">
                            {/* <!-- Account --> */}
                            <div className="card-body">
                                <div className="card-datatable table-responsive ps-3">
                                    <div id="DataTables_Table_0_wrapper" className="dataTables_wrapper dt-bootstrap5 no-footer">
                                        <table className="datatables-customers hm-table table border-top dataTable no-footer dtr-column" id="DataTables_Table_0" aria-describedby="DataTables_Table_0_info">
                                            <tbody>
                                                <tr>
                                                    <td className="sorting">12 Woodlands Road, Bushey, Hertfordshire</td>
                                                </tr>
                                                <tr>
                                                    <td className="sorting">14 Woodlands Road, Bushey, Hertfordshire</td>
                                                </tr>
                                                <tr>
                                                    <td className="sorting">16 Woodlands Road, Bushey, Hertfordshire</td>
                                                </tr>
                                                <tr>
                                                    <td className="sorting">18 Woodlands Road, Bushey, Hertfordshire</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button className='bg-danger border-0' onClick={props.onHide}>Close</Button>
            </Modal.Footer>
        </Modal>
    );
}
export default MyVerticallyCenteredModal;
