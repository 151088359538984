import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const Table = () => {
    const [individual, setIndividual] = useState(true);

    const tableData = [{
        id: 1,
        bookRate: '27-Mar-2024',
        valueRate: '27-Mar-2024',
        dealNum: 'SP34368364',
        bought: '11,610.00 EUR',
        rate: '1.1610',
        sold: '10,000.00 GBP',
        beneficiary: '0',
        status: 'Cancelled',
        statusBg: 'danger'
    },
    {
        id: 2,
        bookRate: '07-Mar-2024',
        valueRate: '07-Mar-2024',
        dealNum: 'SP34368366',
        bought: '115,70.00 EUR',
        rate: '1.1570',
        sold: '100.00 GBP',
        beneficiary: '0',
        status: 'Cancelled',
        statusBg: 'danger'

    },
    {
        id: 3,
        bookRate: '12-Jan-2023',
        valueRate: '12-Jan-2023',
        dealNum: 'SP35368365',
        bought: '11.87 EUR',
        rate: '8.4245',
        sold: '100.CNY',
        beneficiary: '0',
        status: 'Cancelled',
        statusBg: 'danger'

    },
    {
        id: 4,
        bookRate: '12-Jan-2023',
        valueRate: '12-Jan-2023',
        dealNum: 'SP35368365',
        bought: '11.87 EUR',
        rate: '8.4245',
        sold: '100.CNY',
        beneficiary: '1',
        status: 'Completed',
        statusBg: 'success'
    }
    ]
    return (
        <>
            <div className="row">
                <div className="col-12 mb-4">
                    <div className="bs-stepper wizard-numbered mt-5 hm-card">
                        <div className="bs-stepper-header d-flex flex-column flex-md-row">
                            <div className={`step ${individual ? 'active' : ''}`} data-target="#account-details">
                                <button type="button" className="step-trigger bg-transparent border-0" onClick={() => setIndividual(true)}>
                                    <span className="bs-stepper-circle">1</span>
                                    <span className="bs-stepper-label">
                                        <span className="bs-stepper-title fs-5">Recent Transactions</span>
                                    </span>
                                </button>
                            </div>
                            <span className="line"> / </span>
                            <div className={`step ${!individual ? 'active' : ''}`} data-target="#personal-info">
                                <button type="button" className="step-trigger bg-transparent border-0" onClick={() => setIndividual(false)}>
                                    <span className="bs-stepper-circle">2</span>
                                    <span className="bs-stepper-label">
                                        <span className="bs-stepper-title fs-5">Unpaid Deals</span>
                                    </span>
                                </button>
                            </div>
                        </div>
                        <div className="bs-stepper-content">
                            <form onSubmit={() => false}>
                                {individual ? (
                                    <div className="content active dstepper-block">
                                        <div className="table-responsive text-nowrap">
                                            <table className="table table-bordered">
                                                <thead>
                                                    <tr className='text-center '>
                                                        <th><b>Booked Date</b></th>
                                                        <th><b>Value Date</b></th>
                                                        <th><b>Deal Number</b></th>
                                                        <th><b>Bought</b></th>
                                                        <th><b>Rate</b></th>
                                                        <th><b>Sold</b></th>
                                                        <th><b>Beneficiaries</b></th>
                                                        <th><b>Deal Status</b></th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {tableData.map((data) => {
                                                        return (
                                                            <tr key={data.id}>
                                                                <td>
                                                                    <small>{data.bookRate}</small>
                                                                </td>
                                                                <td>
                                                                    <small>{data.valueRate}</small>
                                                                </td>
                                                                <td>
                                                                    <Link to={''} className='text-warning text-decoration-underline'>{data.dealNum}</Link>
                                                                </td>
                                                                <td>
                                                                    <small>{data.bought}</small>
                                                                </td>
                                                                <td>
                                                                    <small>{data.rate}</small>
                                                                </td>
                                                                <td>
                                                                    <small>{data.sold}</small>
                                                                </td>
                                                                <td className='text-center'>
                                                                    {data.beneficiary}
                                                                </td>
                                                                <td>
                                                                    <span className={`badge bg-label-${data.statusBg} me-1 `}>{data.status}</span>
                                                                </td>
                                                            </tr>
                                                        )

                                                    })}

                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                ) : (
                                    <div className="content-header mb-3">

                                    </div>
                                )}

                            </form>
                        </div>
                        <div className="col-12 my-3 d-flex gap-3 justify-content-end">
                            <button className='btn btn-warning gap-3 me-4' >View All Transactions <i className='fa fa-arrow-right'></i></button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Table