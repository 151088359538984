import React, { useState } from "react";
import '../../../../assets/vendor/css/pages/front-page-landing.css'
import ButtonGroup from "./buttonGroup";
import SelectPayments from "./payments/selectPayments";
// import FxRequirement from "./stepper/fxRequirement"; 
// import AuthContact from "./stepper/authContact";
// import KnowledgeSign from "./stepper/knowledgeSign";

export default function Home() {
    const [activeTab, setActiveTab] = useState("selectPayments");

    const handleTabChange = (tab) => {
        setActiveTab(tab);
    };

    const renderTabContent = () => {
        switch (activeTab) {
            case "selectPayments":
                return <SelectPayments handleTabChange={handleTabChange} />;
            // case "verification":
            //     return <FxRequirement handleTabChange={handleTabChange} />;
            // case "submission":
            //     return <AuthContact handleTabChange={handleTabChange} />;
            // case "paymentSummary":
            //     return <KnowledgeSign handleTabChange={handleTabChange} />;
            default:
                return null;
        }
    };

    return (
        <>
            <div className="hero section-py landing-hero p-3 rounded-0">
                <div className="hm-container">
                    <div className="row mb-4">
                        <div className="col-12">
                            <h3 className="text-uppercase my-4">
                                Make Multiple Payments
                            </h3>
                        </div>
                        <div className="col-lg-3">
                            <ButtonGroup activeTab={activeTab} handleTabChange={handleTabChange} />
                        </div>
                        <div className="col-lg-9 sidebar-rightsection">
                            {renderTabContent()}
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}
