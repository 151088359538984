import React from 'react'
import { Link } from 'react-router-dom';
import logo from '../../../../../assets/img/favicon/favicon.ico'

const Navbar = () => {
    return (
        <>
            <nav className="navbar navbar-expand-lg bg-white py-4">
                <div className="container">
                    <Link className="navbar-brand gap-3 d-flex align-items-center" to="">
                        <img src={logo} alt="" />
                        EQUALS Money
                    </Link>
                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasNavbar" aria-controls="offcanvasNavbar" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="offcanvas offcanvas-end" tabIndex="-1" id="offcanvasNavbar" aria-labelledby="offcanvasNavbarLabel" style={{ boxShadow: "none" }}>
                        <div className="offcanvas-header">
                            <h5 className="offcanvas-title" id="offcanvasNavbarLabel">Equals Money</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                        </div>
                        <div className="offcanvas-body bg-transparent">
                            <ul className="navbar-nav justify-content-end flex-grow-1 pe-3 gap-3">
                                <li className="nav-item">
                                    <Link className="nav-link active" aria-current="page" to={"/"}>Login</Link>
                                </li>
                                <li className="nav-item">
                                    <Link className="nav-link bg-primary text-center rounded-2 text-white px-4" to={""}>Open An Account <i className='fa fa-arrow-right ps-2'></i> </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
        </>
    )
}

export default Navbar