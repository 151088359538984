import React from 'react'
import EUR from '../../../../../assets/img/flags/european-union.png'
import GBP from '../../../../../assets/img/flags/united-kingdom.png'
import US from '../../../../../assets/svg/flags/us.svg'

const Calculator = () => {
    return (
        <>
            <div className="row">
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="card hm-card">
                        <h5 className="card-header text-uppercase border-bottom">
                            Currency Calculator
                        </h5>
                        <div className="card-body mt-3">
                            <div className="row">
                                <div className="col-lg-4 my-2">
                                    <label htmlFor="sell">Sell</label>
                                </div>
                                <div className="col-lg-4 my-2">
                                    <input type="text" className='form-control' name='sell' placeholder='10,000' />
                                </div>
                                <div className="col-lg-4 my-2">
                                    <select id="currency" className="select2 form-select" >
                                        <option value="GBP">GBP</option>
                                        <option value="EUR">EUR</option>
                                        <option value="PKR">PKR</option>
                                        <option value="IN">IN</option>
                                    </select>
                                </div>
                                <div className="row d-flex flex-row">
                                    <div className="col-5 my-2">
                                        <label htmlFor="exchangeRate">Exchange Rate</label>
                                    </div>
                                    <div className="col-5 my-2">
                                        <span className="badge bg-label-success me-1 ">1.16464 <i className='fa fa-arrow-up'></i></span>
                                    </div>
                                </div>

                                <div className="col-lg-4 my-2">
                                    <label htmlFor="buy">Buy</label>
                                </div>
                                <div className="col-lg-4 my-2">
                                    <input type="text" className='form-control' name='sell' placeholder='11,646.00' />
                                </div>
                                <div className="col-lg-4 my-2">
                                    <select id="currency" className="select2 form-select" >
                                        <option value="EUR">EUR</option>
                                        <option value="GBP">GBP</option>
                                        <option value="PKR">PKR</option>
                                        <option value="IN">IN</option>
                                    </select>
                                </div>
                                <div className="col-12 my-3 d-flex gap-3 justify-content-center">
                                    <button className='btn btn-warning w-100 gap-3'>Get Started <i className='fa fa-arrow-right'></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12">
                    <div className="card hm-card hm-calculator">
                        <h5 className="card-header border-bottom text-uppercase">
                            Funds On Account
                        </h5>
                        <div className="card-body mt-3">
                            <div className="row">
                                <div className="col-12 my-2">
                                    <div className='d-flex gap-2 align-items-center'>
                                        <img src={GBP} className='rounded-circle' alt="" style={{ width: "33px" }} />0.00 GBP
                                    </div>
                                </div>
                                <div className="col-12 my-2">
                                    <div className='d-flex gap-2 align-items-center'>
                                        <img src={EUR} className='rounded-circle' alt="" style={{ width: "35px" }} />0.00 EUR
                                    </div>
                                </div>
                                <div className="col-12 my-2">
                                    <div className='d-flex gap-2 align-items-center'>
                                        <img src={US} className='rounded-circle' alt="" style={{ width: "35px" }} />0.00 US
                                    </div>
                                </div>
                                <div className="col-12 my-3 d-flex justify-content-center">
                                    <button className='btn btn-dark w-100 d-flex gap-3'>View More Balance <i className='fa fa-arrow-right'></i></button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Calculator