import React from 'react';
import '../../../../assets/vendor/css/pages/front-page.css'
import { Link } from 'react-router-dom';
import insta from '../../../../assets/img/front-pages/icons/instagram-light.png'
import twitter from "../../../../assets/img/front-pages/icons/twitter-light.png";
import facebook from "../../../../assets/img/front-pages/icons/facebook-light.png"
import github from "../../../../assets/img/front-pages/icons/github-light.png"

const Footer = () => {
    return (
        <>
            <footer className="landing-footer bg-body footer-text">
                <div className="footer-top">
                    <div className="hm-container">
                        <div className="row gx-0 gy-4 g-md-5">
                            <div className="col-lg-5">
                                <Link to="landing-page.html" className="app-brand-link mb-4">
                                    <span className="app-brand-logo demo">
                                        <svg width="32" height="22" viewBox="0 0 32 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path fillRule="evenodd" clipRule="evenodd" d="M0.00172773 0V6.85398C0.00172773 6.85398 -0.133178 9.01207 1.98092 10.8388L13.6912 21.9964L19.7809 21.9181L18.8042 9.88248L16.4951 7.17289L9.23799 0H0.00172773Z" fill="#7367F0"></path>
                                            <path opacity="0.06" fillRule="evenodd" clipRule="evenodd" d="M7.69824 16.4364L12.5199 3.23696L16.5541 7.25596L7.69824 16.4364Z" fill="#161616"></path>
                                            <path opacity="0.06" fillRule="evenodd" clipRule="evenodd" d="M8.07751 15.9175L13.9419 4.63989L16.5849 7.28475L8.07751 15.9175Z" fill="#161616"></path>
                                            <path fillRule="evenodd" clipRule="evenodd" d="M7.77295 16.3566L23.6563 0H32V6.88383C32 6.88383 31.8262 9.17836 30.6591 10.4057L19.7824 22H13.6938L7.77295 16.3566Z" fill="#7367F0"></path>
                                        </svg>
                                    </span>
                                    <span className="app-brand-text demo footer-link fw-bold ms-2 ps-1 text-uppercase">EQUALS Connect Limited</span>
                                </Link>
                                <p className="footer-text footer-logo-description mb-4">
                                    Payment services are by Equals Connet=ct Limited, a company incorporated in England & Wales
                                </p>
                            </div>
                            <div className="col-lg-4 col-md-4 col-sm-6">
                                <h6 className="footer-title mb-4">LEGAL</h6>
                                <ul className="list-unstyled">
                                    <li className="mb-3">
                                        <Link to="" target="_blank" className="footer-link">Terms & Condition</Link>
                                    </li>
                                    <li className="mb-3">
                                        <Link to="" target="_blank" className="footer-link">Privacy Policy</Link>
                                    </li>
                                    <li className="mb-3">
                                        <Link to="" target="_blank" className="footer-link">Modern Slavery Statement</Link>
                                    </li>
                                    <li className="mb-3">
                                        <Link to="" target="_blank" className="footer-link">Terms Of Website Use</Link>
                                    </li>
                                    <li className="mb-3">
                                        <Link to="" target="_blank" className="footer-link">Website Acceptable Use Policy</Link>
                                    </li>
                                </ul>
                            </div>
                            <div className="col-lg-3 col-md-4">
                                <h6 className="footer-title mb-4 text-uppercase">Contact</h6>
                                <small className="d-block footer-link mb-3 pb-2">P : +44(0)20 3196 2187</small>
                                <small className="d-block footer-link">E: contact@equalsconnect.com</small>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="footer-bottom py-3">
                    <div className="container d-flex flex-wrap justify-content-between flex-md-row flex-column text-center text-md-start">
                        <div className="mb-2 mb-md-0">
                            <span className="footer-text">©
                                <script>
                                    document.write(new Date().getFullYear());
                                </script>2024
                            </span>
                            <span className="footer-text"> Made with ❤️ for a better web.</span>
                        </div>
                        <div>
                            <Link to="https://github.com/pixinvent" className="footer-link me-3" target="_blank">
                                <img src={github} alt="github icon" data-app-light-img="front-pages/icons/github-light.png" data-app-dark-img="front-pages/icons/github-dark.png" />
                            </Link>
                            <Link to="https://www.facebook.com/pixinvents/" className="footer-link me-3" target="_blank">
                                <img src={facebook} alt="facebook icon" data-app-light-img="front-pages/icons/facebook-light.png" data-app-dark-img="front-pages/icons/facebook-dark.png" />
                            </Link>
                            <Link to="https://twitter.com/pixinvents" className="footer-link me-3" target="_blank">
                                <img src={twitter} alt="twitter icon" data-app-light-img="front-pages/icons/twitter-light.png" data-app-dark-img="front-pages/icons/twitter-dark.png" />
                            </Link>
                            <Link to="https://www.instagram.com/pixinvents/" className="footer-link" target="_blank">
                                <img src={insta} alt="google icon" data-app-light-img="front-pages/icons/instagram-light.png" data-app-dark-img="front-pages/icons/instagram-dark.png" />
                            </Link>
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default Footer