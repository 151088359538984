import React from "react";
import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import Register from "./components/auth/register";
import Login from "./components/auth/login";
import RegisterPage from "./components/auth/registerPages/pages/main";
import { Toaster } from "react-hot-toast";
import Payment from "./components/dashboard/pages/payment/paymentHistory";
import Navbar from "./components/dashboard/layouts/navbar/navbar";
import Dashboard from "./components/dashboard/pages/home/dashboard";
import BeneficiaryManagent from "./components/dashboard/pages/manageBeneficiary/beneficiaryManage";
import AddNewBeneficiary from "./components/dashboard/pages/manageBeneficiary/addNewBeneficiary";
import Footer from "./components/dashboard/layouts/footer/footer";
import DealHistory from "./components/dashboard/pages/dealHistory/dealHistory";
import PaymentHistory from "./components/dashboard/pages/paymentHistory/paymentHistory";
import Profile from "./components/dashboard/pages/profile/profile";
import SpotRate from "./components/dashboard/pages/spotRate/spotRate";
import ForwardRate from "./components/dashboard/pages/forwardRate/forwardRate";
import Wallet from "./components/dashboard/pages/wallet/wallet";


const App = () => {
  return (
    <>
      <Toaster />
      <BrowserRouter>
        <AppContent />
      </BrowserRouter>
    </>
  );
};

const AppContent = () => {
  const location = useLocation();

  // Function to determine whether to show the Navbar based on the current route
  const showNavbar = () => {
    return !(location.pathname === "/" || location.pathname === "/register" || location.pathname === "/registration");
  };

  const showFooter = () => {
    return !(location.pathname === "/" || location.pathname === "/register");
  };

  return (
    <>
      {showNavbar() && <Navbar />} {/* Conditionally render Navbar */}
      <Routes>
        {/* resgistration Pages */}
        <Route path="/" element={<Login />} />
        <Route path="/register" element={<Register />} />
        <Route path="/registration" element={<RegisterPage />} />

        {/* dashboard Pages */}
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/payment" element={<Payment />} />
        <Route path="/deal-history" element={<DealHistory />} />
        <Route path="/payment-history" element={<PaymentHistory />} />
        <Route path="/manage-beneficiaries" element={<BeneficiaryManagent />} />
        <Route path="/manage-beneficiaries/add-new-beneficiaries" element={<AddNewBeneficiary />} />
        <Route path="/profile" element={<Profile />} />
        <Route path="/spot-rate" element={<SpotRate />} />
        <Route path="/forward-rate" element={<ForwardRate />} />
        <Route path="/wallet" element={<Wallet />} />
      </Routes>
      {showFooter() && <Footer />} {/* Conditionally render footee */}

    </>
  );
};

export default App;