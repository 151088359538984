import React, { useState } from 'react';
import user from "../../../../assets/img/avatars/1.png";
import { Link } from 'react-router-dom';

const Account = () => {

    const [menu, setMenu] = useState("accounts");
    const [passwordVisible, setPasswordVisible] = useState({
        currentPassword: false,
        newPassword: false,
        confirmPassword: false,
    });

    const AccountCom = () => {
        document.getElementById("hm_security").style.display = "none";
        document.getElementById("hm_account").style.display = "block";
        setMenu("accounts");
    }

    const SecurityCom = () => {
        document.getElementById("hm_account").style.display = "none";
        document.getElementById("hm_security").style.display = "block";
        setMenu("Security");
    }

    const togglePasswordVisibility = (field) => {
        setPasswordVisible((prevState) => ({
            ...prevState,
            [field]: !prevState[field],
        }));
    };

    return (
        <>
            <div className="row fv-plugins-icon-container">
                <div className="col-md-12">
                    <ul className="nav nav-pills flex-column flex-md-row mb-4">
                        <li className="nav-item">
                            <button onClick={AccountCom} className={`nav-link ${menu === "accounts" ? "active" : ""}`}><i className="ti-xs ti ti-users me-1"></i> Account</button>
                        </li>
                        <li className="nav-item">
                            <button onClick={SecurityCom} className={`nav-link ${menu === "Security" ? "active" : ""}`}><i className="ti-xs ti ti-lock me-1"></i> Security</button>
                        </li>
                    </ul>
                    <div id='hm_account'>
                        <div className="card mb-4">
                            <h5 className="card-header m-0">Profile Details</h5>
                            <hr className='mt-0 mb-5' />
                            {/* <!-- Account --> */}
                            <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
                                <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                                    <img src={user} alt="user" className="d-block h-auto ms-0 ms-sm-4 rounded user-profile-img" />
                                </div>
                                <div className="flex-grow-1">
                                    <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                                        <div className="user-profile-info">
                                            <h3 className='text-primary fw-bolder'>BENJAMIN GRANGER</h3>
                                            <span><b>bsg1769@gmail.com</b></span>
                                        </div>
                                        <Link to="" className="btn btn-success waves-effect waves-light">
                                            <i className="ti ti-check me-1"></i> All Completed
                                        </Link>
                                    </div>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-lg-5">
                                        <h5 className="card-text text-uppercase text-primary fw-bolder">About</h5>
                                        <ul className="list-unstyled mb-4 mt-3">
                                            <li className="d-flex align-items-center mb-3">
                                                <i className="ti ti-user text-heading"></i><span className="fw-medium mx-2 text-heading fw-bolder">First Name:</span> <span>John</span>
                                            </li>
                                            <li className="d-flex align-items-center mb-3">
                                                <i className="ti ti-user text-heading"></i><span className="fw-medium mx-2 text-heading fw-bolder">Last Name:</span> <span>Doe</span>
                                            </li>
                                            <li className="d-flex align-items-center mb-3">
                                                <i className="ti ti-user text-heading"></i><span className="fw-medium mx-2 text-heading fw-bolder">Date Of Birth</span> <span>04-08-1988</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-5">
                                        <h5 className="card-text text-uppercase text-primary fw-bolder">Address Details</h5>
                                        <ul className="list-unstyled mb-4 mt-3">
                                            <li className="d-flex align-items-center mb-3">
                                                <i className="ti ti-world"></i><span className="fw-medium mx-2 text-heading fw-bolder">Country :</span>
                                                <span>United Kingdom</span>
                                            </li>
                                            <li className="d-flex align-items-center mb-3">
                                                <i className="ti ti-phone-call"></i><span className="fw-medium mx-2 text-heading fw-bolder">Poat Code :</span>
                                                <span>NW7 1GR</span>
                                            </li>
                                            <li className="d-flex align-items-center mb-3">
                                                <i className="ti ti-location"></i><span className="fw-medium mx-2 text-heading fw-bolder">Address :</span>
                                                <span>Flat 24 Paveley Court 30 Langstone Way</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-5">
                                        <h5 className="card-text text-uppercase text-primary fw-bolder">Contacts</h5>
                                        <ul className="list-unstyled mb-4 mt-3">
                                            <li className="d-flex align-items-center justify-content-between mb-3">
                                                <span>
                                                    <i className="ti ti-phone-call"></i><span className="fw-medium mx-2 text-heading fw-bolder">Contact:</span>
                                                    <span>(123) 456-7890</span>
                                                </span>
                                                <span className='text-warning fw-bolder cursor-pointer'>Change</span>
                                            </li>
                                            <li className="d-flex align-items-center justify-content-between mb-3">
                                                <span>
                                                    <i className="ti ti-mail"></i><span className="fw-medium mx-2 text-heading fw-bolder">Email:</span>
                                                    <span>john.doe@example.com</span>
                                                </span>
                                                <span className='text-warning fw-bolder cursor-pointer'>Change</span>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="col-lg-5">
                                        <h5 className="card-text text-uppercase text-primary fw-bolder">Time Zone</h5>
                                        <ul className="list-unstyled mb-4 mt-3">
                                            <li className="d-flex align-items-center mb-3">
                                                <i className="ti ti-chart-pie-2"></i><span className="fw-medium mx-2 text-heading fw-bolder">Time Zone:</span>
                                                <span>Europe/ London</span>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>
                        {/* <!-- /Account --> */}
                    </div>
                    <div id="hm_security" style={{ display: "none" }}>
                        <div className="row">
                            <div className="col-md-12">
                                {/* <!-- Change Password --> */}
                                <div className="card mb-4">
                                    <h5 className="card-header">Change Password</h5>
                                    <div className="card-body">
                                        <form id="formAccountSettings" className="fv-plugins-bootstrap5 fv-plugins-framework" novalidate="novalidate">
                                            <div className="row">
                                                <div className="mb-3 col-md-6 form-password-toggle fv-plugins-icon-container">
                                                    <label className="form-label" htmlFor="currentPassword">Current Password</label>
                                                    <div className="input-group input-group-merge has-validation">
                                                        <input className="form-control" type={passwordVisible.currentPassword ? "text" : "password"} name="currentPassword" id="currentPassword" placeholder="············" />
                                                        <span className="input-group-text cursor-pointer" onClick={() => togglePasswordVisibility("currentPassword")}><i className={`ti ${passwordVisible.currentPassword ? 'ti-eye' : 'ti-eye-off'}`}></i></span>
                                                    </div><div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="mb-3 col-md-6 form-password-toggle fv-plugins-icon-container">
                                                    <label className="form-label" htmlFor="newPassword">New Password</label>
                                                    <div className="input-group input-group-merge has-validation">
                                                        <input className="form-control" type={passwordVisible.newPassword ? "text" : "password"} id="newPassword" name="newPassword" placeholder="············" />
                                                        <span className="input-group-text cursor-pointer" onClick={() => togglePasswordVisibility("newPassword")}><i className={`ti ${passwordVisible.newPassword ? 'ti-eye' : 'ti-eye-off'}`}></i></span>
                                                    </div><div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                                </div>

                                                <div className="mb-3 col-md-6 form-password-toggle fv-plugins-icon-container">
                                                    <label className="form-label" htmlFor="confirmPassword">Confirm New Password</label>
                                                    <div className="input-group input-group-merge has-validation">
                                                        <input className="form-control" type={passwordVisible.confirmPassword ? "text" : "password"} name="confirmPassword" id="confirmPassword" placeholder="············" />
                                                        <span className="input-group-text cursor-pointer" onClick={() => togglePasswordVisibility("confirmPassword")}><i className={`ti ${passwordVisible.confirmPassword ? 'ti-eye' : 'ti-eye-off'}`}></i></span>
                                                    </div><div className="fv-plugins-message-container fv-plugins-message-container--enabled invalid-feedback"></div>
                                                </div>
                                                <div className="col-12 mb-4">
                                                    <h6>Password Requirements:</h6>
                                                    <ul className="ps-3 mb-0">
                                                        <li className="mb-1">Minimum 8 characters long - the more, the better</li>
                                                        <li className="mb-1">At least one lowercase character</li>
                                                        <li>At least one number, symbol, or whitespace character</li>
                                                    </ul>
                                                </div>
                                                <div>
                                                    <button type="submit" className="btn btn-primary me-2 waves-effect waves-light">Save changes</button>
                                                    <button type="reset" className="btn btn-label-secondary waves-effect">Cancel</button>
                                                </div>
                                            </div>
                                            <input type="hidden" /></form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div >
            </div >
        </>
    );
}

export default Account