// ButtonGroup.js
import React from 'react';

const MultiplePayments = ({ activeTab, handleTabChange }) => {
  return (
    <div className="card h-100 hm-buttons">
      <div className="card-header">
        <ul className="nav nav-pills card-header-pills hm-buttons-ul d-flex flex-column justify-content-start" role="tablist">
          <li className="nav-item py-3" role="presentation">
            <button type="button" onClick={() => handleTabChange("selectPayments")} className={`nav-link display-1 justify-content-lg-start ${activeTab === "selectPayments" ? "active" : ""}`}>
              1. Select Payments
              {/* <i className='fa fa-arrow-right' style={{ display: `${activeTab === "selectPayments" ? 'block' : 'none'}` }}></i> */}
            </button>
          </li>

          <li className="nav-item py-3" role="presentation">
            <button type="button" onClick={() => handleTabChange("verification")} className={`nav-link display-1 justify-content-lg-start  ${activeTab === "verification" ? "active" : ""}`} >
              2. Verify
              {/* <i className='fa fa-arrow-right' style={{ display: `${activeTab === "verification" ? 'block' : 'none'}` }}></i> */}
            </button>
          </li>

          <li className="nav-item py-3" role="presentation">
            <button type="button" onClick={() => handleTabChange("submission")} className={`nav-link display-1 justify-content-lg-start text-start ${activeTab === "submission" ? "active" : ""}`} >
              3. Book & Submit
              {/* <i className='fa fa-arrow-right' style={{ display: `${activeTab === "submission" ? 'block' : 'none'}` }}></i> */}
            </button>
          </li>

          <li className="nav-item py-3" role="presentation">
            <button type="button" onClick={() => handleTabChange("paymentSummary")} className={`nav-link display-1 justify-content-lg-start text-start ${activeTab === "paymentSummary" ? "active" : ""}`} >
              4. Payment Summary
              {/* <i className='fa fa-arrow-right' style={{ display: `${activeTab === "paymentSummary" ? 'block' : 'none'}` }}></i> */}
            </button>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default MultiplePayments;
