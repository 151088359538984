import React from 'react'
import Account from './account'

const Profile = () => {
    return (
        <>
            <div className="hero section-py landing-hero p-3 rounded-0">
                <div className="hm-container">
                    <div className="row">
                        <div className="col-12">
                            {/* <div className="card hm-card mb-4">
                                <div className="user-profile-header-banner">
                                    <img src={banner} alt="Banner" className="rounded-top w-100" />
                                </div>
                                <div className="user-profile-header d-flex flex-column flex-sm-row text-sm-start text-center mb-4">
                                    <div className="flex-shrink-0 mt-n2 mx-sm-0 mx-auto">
                                        <img src={avatar} alt="user" className="d-block h-auto ms-0 ms-sm-4 rounded user-profile-img" />
                                    </div>
                                    <div className="flex-grow-1 mt-3">
                                        <div className="d-flex align-items-md-end align-items-sm-start align-items-center justify-content-md-between justify-content-start mx-4 flex-md-row flex-column gap-4">
                                            <div className="user-profile-info">
                                                <h3 className='mb-1 text-primary fw-bolder'>BENJAMIN GRANGER</h3>
                                                <span><b>bdg1769@gmail.com</b></span>
                                            </div>
                                            <Link className="btn btn-success waves-effect waves-light">
                                                <i className="ti ti-check me-1"></i>All Completed
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div> */}
                            <Account />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile