import React from 'react';
import MyVerticallyCenteredModal from './modal/modal';
import { Button } from 'react-bootstrap';

const SelectPayments = ({ handleTabChange }) => {

    const [modalShow, setModalShow] = React.useState(false);


    return (
        <div className="card">
            <div className="card-header pb-0 d-flex align-items-center justify-content-between flex-column flex-md-row">
                <h6 className="card-title mb-0 text-muted">Please Choose your beneficiaries from the list or add someone new below.</h6>
                <button className="dt-button create-new btn btn-primary waves-effect waves-light gap-2" tabindex="0" aria-controls="DataTables_Table_0" type="button">
                    <i className="ti ti-upload me-sm-1"></i>
                    <span className="d-none d-sm-inline-block">Add New Record</span>
                </button>
            </div>
            <div className="card-body p-4">
                <div className="table-responsive">
                    <table className="dt-column-search table dataTable" id="DataTables_Table_1" aria-describedby="DataTables_Table_1_info" style={{ width: "1045px;" }}>
                        <thead>
                            <tr>
                                <th className="sorting sorting_asc" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1" colspan="1" aria-sort="ascending" aria-label="No: activate to sort column descending" style={{ width: "132px;" }}>No</th>
                                <th className="sorting sorting_asc" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1" colspan="1" aria-sort="ascending" aria-label="Beneficiary: activate to sort column descending" style={{ width: "132px;" }}>Beneficiary</th>
                                <th className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1" colspan="1" aria-label="Currency: activate to sort column ascending" style={{ width: "130px;" }}>Currency</th>
                                <th className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1" colspan="1" aria-label="Amount: activate to sort column ascending" style={{ width: "126px;" }}>Amount</th>
                                <th className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1" colspan="1" aria-label="Payment Refrence: activate to sort column ascending" style={{ width: "124px;" }}>Payment Refrence</th>
                                <th className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1" colspan="1" aria-label="Unique Identifier (Optional): activate to sort column ascending" style={{ width: "126px;" }}>Unique Identifier <span>(Optional)</span></th>
                                <th className="sorting" tabindex="0" aria-controls="DataTables_Table_1" rowspan="1" colspan="1" aria-label="Action: activate to sort column ascending" style={{ width: "139px;" }}>Action</th>
                            </tr>
                            <tr>
                                <th rowspan="1" colspan="1">
                                    1
                                </th>
                                <th rowspan="1" colspan="1">
                                    <select name="beneficiary" id="beneficiaryName" className='form-select'>
                                        <option value="">Select beneficiary</option>
                                    </select>
                                </th>
                                <th rowspan="1" colspan="1">

                                </th>
                                <th rowspan="1" colspan="1">
                                    <input type="text" className="form-control" placeholder="Amount" />
                                </th>
                                <th rowspan="1" colspan="1">
                                    <input type="text" className="form-control" placeholder="Enter Payment" />
                                </th>
                                <th rowspan="1" colspan="1">
                                    <input type="text" className="form-control" placeholder="Unique Identifier" />
                                </th>
                                <th rowspan="1" colspan="1">
                                    <i className="ti ti-folder text-info me-1 cursor-pointer"></i>
                                    <i className="ti ti-trash text-danger me-1 cursor-pointer"></i>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="odd">
                                <td valign="top" colspan="6" className="dataTables_empty">Loading...</td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className="add_button d-flex justify-content-between my-4">
                    <Button className="btn btn-primary px-auto" onClick={() => setModalShow(true)}>
                        + Add New Beneficiary
                    </Button>
                    <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)}
                    />
                    <button
                        type="button"
                        className="btn btn-warning"
                    >
                        + Add Another Payment
                    </button>
                </div>
            </div>
            <div className="card-footer bg-light d-flex justify-content-end gap-3 p-4">
                <button
                    type='submit'
                    onClick={() => handleTabChange("verification")}
                    className='btn btn-success gap-2'
                >
                    Proceed <i className='fa fa-arrow-right'></i>
                </button>
            </div>
        </div>
    )
}

export default SelectPayments