import React from 'react'
import { Link } from 'react-router-dom';

const Table = () => {

    const tableData = [{
        id: 1,
        bookRate: '27-Mar-2024',
        beneficiaryName: 'HELLOGROUP S.R.L',
        dealNum: 'SP34368364',
        amount: '50.00 EUR',
        accountNo: 'IT35468243514387138354',
        country: 'Italy',
        beneficiary: '0',
        status: 'Cancelled',
        statusBg: 'danger'
    },

    ]

    return (
        <>
            <form>
                <div className="content active dstepper-block">
                    <div className="table-responsive text-nowrap">
                        <table className="table table-bordered" id="table-to-export">
                            <thead>
                                <tr className='text-center '>
                                    <th style={{width : ''}}><b>Date</b></th>
                                    <th><b>Deal Number</b></th>
                                    <th><b>Beneficiary Name</b></th>
                                    <th><b>Payment Amount</b></th>
                                    <th><b>IBAN / Account No</b></th>
                                    <th><b>Country</b></th>
                                    <th><b>Payment Status</b></th>
                                    <th></th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((data) => {
                                    return (
                                        <tr key={data.id} className='text-center'>
                                            <td>
                                                <small>{data.bookRate}</small>
                                            </td>
                                            <td>
                                                <Link to={''} className='text-warning text-decoration-underline'>{data.dealNum}</Link>
                                            </td>
                                            <td>
                                                <small>{data.beneficiaryName}</small>
                                            </td>
                                            <td>
                                                <small>{data.amount}</small>
                                            </td>
                                            <td>
                                                <small>{data.accountNo}</small>
                                            </td>
                                            <td>
                                                <small>{data.country}</small>
                                            </td>
                                            <td>
                                                <span className={`badge bg-label-${data.statusBg} me-1 `}>{data.status}</span>
                                            </td>
                                            <td></td>
                                        </tr>
                                    )

                                })}

                            </tbody>
                        </table>
                        <div className="d-flex justify-content-between mt-4">
                            <div className="d-flex align-items-center gap-5" style={{ width: "max-content" }} id="DataTables_Table_2_length">
                                <label className='d-flex'>Page</label>
                                <select className="form-select">
                                    <option value="7">7</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <nav aria-label="Page navigation" className='d-flex align-items-center'>
                                <ul className="pagination pagination-sm">
                                    <li className="page-item prev">
                                        <Link className="page-link waves-effect"><i className="tf-icon fs-6 ti ti-chevrons-left"></i></Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link waves-effect">1</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link waves-effect">2</Link>
                                    </li>
                                    <li className="page-item active">
                                        <Link className="page-link waves-effect">3</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link waves-effect">4</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link waves-effect">5</Link>
                                    </li>
                                    <li className="page-item next">
                                        <Link className="page-link waves-effect"><i className="tf-icon fs-6 ti ti-chevrons-right"></i></Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="content-header mb-3">
                </div>
            </form>
        </>
    )
}

export default Table