import React, { useState } from 'react';
import MyVerticallyCenteredModal from './modal/modal';
import { Button } from 'react-bootstrap';

const AuthContact = ({ handleTabChange }) => {
  const [modalShow, setModalShow] = useState(false);
  const [showAdditionalRow, setShowAdditionalRow] = useState(false);
  const [additionalContact, setAdditionalContact] = useState({
    authorization: '',
    firstName: '',
    lastName: '',
    // Add more fields if necessary
  });
  const [mainTableData, setMainTableData] = useState([
    // Initial data for the main table
    { authorization: 'Primary Authorised contact', firstName: 'Sams', lastName: 'Franks' },
    // Add more initial data as necessary
  ]);

  const toggleAdditionalRow = () => {
    setShowAdditionalRow(!showAdditionalRow);
  };

  const handleConfirm = (e) => {
    e.preventDefault();

    const updatedAdditionalContact = { ...additionalContact, authorization: 'Additional Authorised contact' };

    // Update main table data with additional contact
    setMainTableData([...mainTableData,updatedAdditionalContact]);

    // Reset additional contact state
    setAdditionalContact({
      firstName: '',
      lastName: '',
      // Reset other fields if necessary
    });

    // Hide additional row
    setShowAdditionalRow(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAdditionalContact((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <div className="card">
      <div className="card-body p-4">
        <h3 className='mb-5'>
          Additional Authorised contact
        </h3>
        <div className="table-responsive card-datatable">
          <table className="table table-bordered">
            <tbody>
              {mainTableData.map((contact, index) => (
                <tr key={index}>
                  <td><b>{contact.authorization}</b></td>
                  <td>{contact.firstName} {contact.lastName}</td>
                  <td>
                    <div className="dropdown">
                      <button
                        type="button"
                        className="btn p-0 dropdown-toggle hide-arrow"
                        data-bs-toggle="dropdown"
                      >
                        <i className="ti ti-dots-vertical"></i>
                      </button>
                      <div className="dropdown-menu">
                        <button className="dropdown-item">
                          <i className="ti ti-pencil me-1"></i> Edit
                        </button>
                        <button className="dropdown-item">
                          <i className="ti ti-trash me-1"></i> Delete
                        </button>
                      </div>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          <div className="add_button mt-3">
            <button
              type="button"
              className={`btn ${showAdditionalRow ? "btn-danger" : "btn-primary"}`}
              onClick={toggleAdditionalRow}
            >
              {showAdditionalRow ? "- Cancel" : "+ Add"}
            </button>
          </div>
          {showAdditionalRow && (
            <>
              <form action="">
                <div className="row mt-4 border-top pt-3">
                  <div className="col-sm-12 col-md-12 col-lg-5 my-2">
                    <input
                      className="form-control"
                      type="text"
                      id="firstName"
                      name="firstName"
                      placeholder="First Name"
                      onChange={handleChange}
                      value={additionalContact.firstName}
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-4 my-2">
                    <input
                      className="form-control"
                      type="text"
                      id="lastName"
                      name="lastName"
                      placeholder="Last Name"
                      onChange={handleChange}
                      value={additionalContact.lastName}
                    />
                  </div>


                  <div className="col-sm-12 col-md-6 col-lg-3 my-2 p-0">
                    <button type="submit" className="btn btn-primary">Find Contact</button>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6 my-2 d-flex">
                    <small>Date of Birth : </small>
                    <input className="form-control" id="dateOfBirth" name="dateOfBirth" type="date"
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-2 my-2">
                    <select id="numberCode" className="select2 form-select">
                      <option value="">+44</option>
                      <option value="Thailand">+92</option>
                      <option value="Turkey">+91</option>
                    </select>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-4 my-2">
                    <input className="form-control" type="text" id="contactNumber" name="contactNumber" placeholder="Mobile Number"
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-3 my-2">
                    <input className="form-control" type="text" id="userpostcode" name="userpostcode" placeholder="Post Code"
                    />
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-3 my-2">
                    <Button className="btn btn-primary px-auto" onClick={() => setModalShow(true)}>
                      Find Address
                    </Button>
                    <MyVerticallyCenteredModal show={modalShow} onHide={() => setModalShow(false)}
                    />
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-6 my-3">
                    <select id="userCountry" className="select2 form-select">
                      <option value="">Select Country</option>
                      <option value="Thailand">Thailand</option>
                      <option value="Turkey">Turkey</option>
                      <option value="Ukraine">Ukraine</option>
                      <option value="United Arab Emirates">United Arab Emirates</option>
                      <option value="United Kingdom">United Kingdom</option>
                      <option value="United States">United States</option>
                    </select>
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-12 my-2">
                    <input className="form-control" type="text" id="userAddress" name="userAddress" placeholder="Address"

                    />
                  </div>

                  <div className="col-sm-12 col-md-12 col-lg-12 my-2">
                    <input className="form-control" type="email" id="email" name="email" placeholder="Email"

                    />
                  </div>


                  <div className="button">
                    <button className='btn btn-warning' onClick={handleConfirm}>Confirm</button>
                  </div>
                </div>
              </form>
            </>
          )}
        </div>
      </div>
      <div className="card-footer bg-light d-flex justify-content-end gap-3 p-4">
        <button
          type='submit'
          onClick={() => handleTabChange("FxRequirement")}
          className='btn bg-white gap-2'
        >
          Back
        </button>
        <button
          type='submit'
          onClick={() => handleTabChange("knowledgeSign")}
          className='btn btn-primary gap-2'
        >
          Continue <i className='fa fa-arrow-right'></i>
        </button>
      </div>
    </div>
  );
};

export default AuthContact;
