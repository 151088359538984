import React from 'react'
import { Link } from 'react-router-dom';

const Table = () => {

    const tableData = [{
        id: 1,
        bookRate: '27-Mar-2024',
        valueRate: '27-Mar-2024',
        dealNum: 'SP34368364',
        bought: '11,610.00 EUR',
        rate: '1.1610',
        sold: '10,000.00 GBP',
        beneficiary: '0',
        status: 'Cancelled',
        statusBg: 'danger'
    },
    {
        id: 2,
        bookRate: '07-Mar-2024',
        valueRate: '07-Mar-2024',
        dealNum: 'SP34368366',
        bought: '115,70.00 EUR',
        rate: '1.1570',
        sold: '100.00 GBP',
        beneficiary: '0',
        status: 'Cancelled',
        statusBg: 'danger'

    },
    {
        id: 3,
        bookRate: '12-Jan-2023',
        valueRate: '12-Jan-2023',
        dealNum: 'SP35368365',
        bought: '11.87 EUR',
        rate: '8.4245',
        sold: '100.CNY',
        beneficiary: '0',
        status: 'Cancelled',
        statusBg: 'danger'

    },
    {
        id: 4,
        bookRate: '12-Jan-2023',
        valueRate: '12-Jan-2023',
        dealNum: 'SP35368365',
        bought: '11.87 EUR',
        rate: '8.4245',
        sold: '100.CNY',
        beneficiary: '1',
        status: 'Completed',
        statusBg: 'success'
    }
    ]

    return (
        <>
            <form>
                <div className="content active dstepper-block">
                    <div className="table-responsive text-nowrap">
                        <table className="table table-bordered" id="table-to-export">
                            <thead>
                                <tr className='text-center '>
                                    <th><b>Booked Rate</b></th>
                                    <th><b>Value Date</b></th>
                                    <th><b>Deal Number</b></th>
                                    <th><b>Bought</b></th>
                                    <th><b>Rate</b></th>
                                    <th><b>Sold</b></th>
                                    <th><b>Beneficiaries</b></th>
                                    <th><b>Deal Status</b></th>
                                </tr>
                            </thead>
                            <tbody>
                                {tableData.map((data) => {
                                    return (
                                        <tr key={data.id}>
                                            <td>
                                                <small>{data.bookRate}</small>
                                            </td>
                                            <td>
                                                <small>{data.valueRate}</small>
                                            </td>
                                            <td>
                                                <Link to={''} className='text-warning text-decoration-underline'>{data.dealNum}</Link>
                                            </td>
                                            <td>
                                                <small>{data.bought}</small>
                                            </td>
                                            <td>
                                                <small>{data.rate}</small>
                                            </td>
                                            <td>
                                                <small>{data.sold}</small>
                                            </td>
                                            <td className='text-center'>
                                                {data.beneficiary}
                                            </td>
                                            <td>
                                                <span className={`badge bg-label-${data.statusBg} me-1 `}>{data.status}</span>
                                            </td>
                                        </tr>
                                    )

                                })}

                            </tbody>
                        </table>
                        <div className="d-flex justify-content-between mt-4">
                            <div className="d-flex align-items-center gap-5" style={{ width: "max-content" }} id="DataTables_Table_2_length">
                                <label className='d-flex'>Page</label>
                                <select className="form-select">
                                    <option value="7">7</option>
                                    <option value="10">10</option>
                                    <option value="25">25</option>
                                    <option value="50">50</option>
                                    <option value="75">75</option>
                                    <option value="100">100</option>
                                </select>
                            </div>
                            <nav aria-label="Page navigation" className='d-flex align-items-center'>
                                <ul className="pagination pagination-sm">
                                    <li className="page-item prev">
                                        <Link className="page-link waves-effect"><i className="tf-icon fs-6 ti ti-chevrons-left"></i></Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link waves-effect">1</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link waves-effect">2</Link>
                                    </li>
                                    <li className="page-item active">
                                        <Link className="page-link waves-effect">3</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link waves-effect">4</Link>
                                    </li>
                                    <li className="page-item">
                                        <Link className="page-link waves-effect">5</Link>
                                    </li>
                                    <li className="page-item next">
                                        <Link className="page-link waves-effect"><i className="tf-icon fs-6 ti ti-chevrons-right"></i></Link>
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="content-header mb-3">
                </div>
            </form>
        </>
    )
}

export default Table